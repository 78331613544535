
.section__article {
  p {
    margin-bottom: 2rem;
  }
  img {
    display: block;
    width: 100%;
    margin: 5rem 0 !important;
    vertical-align: middle;
    border-style: none;
  }
  @media (max-width: 768px) {
    blockquote {
      margin-bottom: 24px;
      padding: 10px 24px;
      font-size: 16px;
      line-height: 1.5;
    }
    .section__library-doc-title {
      font-size: 18px!important;
    }
    .section__library-doc-view {
      font-size: 16px;
    }
    .content_title {
      margin-top: 40px;
    }
  }
}
.news-short-text {
  margin-bottom: 15px;
  font-style: italic;
  font-size: 2.4rem;
  @media (max-width: 768px) {
    font-size: 18px;
  }
}
