
.href {
  font-weight: 600;
  color: #002E5E;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
.section__education-tag-content {
  display: -webkit-box;
  max-height: 140px;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
