
@media (max-width: 768px) {
  .feedback-content {
    white-space: normal;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    max-height: 14rem!important;
  }
  .section__school-container-links {
    margin-top: 0!important;
  }
}
