
.section__calendar-search-actions {
  display: none;
}
.pmu-evented {
  background: #1C75BC!important;
  color: #ffffff!important;
  &.pmu-not-in-month {
    background: #E0E0E0!important;
    color: #BDBDBD!important;
  }
  &.pmu-selected {
    background: #002E5E!important;
    color: #fff!important;
  }
}
.pickmeup .pmu-instance .pmu-button.pmu-evented:not(.pmu-not-in-month):hover {
  background: #002E5E!important;
  color: #fff!important;
}
