
.section__about-all h1 {
  margin-bottom: 2.4rem!important;
  font-weight: 700!important;
  font-size: 3.2rem!important;
}
.section__about-all blockquote {
  margin-bottom: 2rem;
  padding: 2.4rem 4.8rem;
  background: #fff;
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 1.3;
  border-left: 0.8rem solid #ed1b2f;
}
@media (max-width: 768px) {
  .section__about-all h1 {
    font-size: 24px!important;
    line-height: 1!important;
    margin-bottom: 24px!important;
  }
}
